<template>
  <div class="content d-flex flex-column flex-column-fluid" id="kt_content">
    <Breadcrumb :datas='breadcrumb_data' />
    <!--begin::Entry-->
    <div class="d-flex flex-column-fluid">
      <!--begin::Container-->
      <div class="container">
        <!--begin::Card-->
        <div class="card card-custom">
          <div class="card-header flex-wrap border-0 pt-6 pb-0">
            <div class="card-title">
              <h3 class="card-label">
                {{ $t('c_management.user_attach_subtitle') }}
              </h3>
            </div>
          </div>
          <div class="card-body">
            <Datatable
                ref="datatable"
                :url="datatableUrl"
                :dataUrl="datatableDataUrl"
                @data="loadDatatableData($event)"
            >
                <!-- <template v-slot:head>
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">{{ $t('c_management.user') }}</th>
                    <th scope="col">{{ $t('commons.type') }}</th>
                    <th scope="col">{{ $t('commons.file') }}</th>
                    <th scope="col">{{ $t('commons.status') }}</th>
                    <th scope="col">{{ $t('commons.authorized') }}</th>
                    <th scope="col">{{ $t('commons.time_addition') }}</th>
                  </tr>
                </template> -->
                <template v-slot:body>
                  <tr v-for="(item, index) in datatableData" :key="'data-' + index">
                    <th scope="row">{{ item.id }}</th>
                    <td v-html="item.model_id"></td>
                    <td>{{ (item.type) ? $t(`service.${item.type}`) : item.type }}</td>
                    <td>
                      <a :href="item.file" target="_blank">{{ $t('service.commons.show_file') }}</a>
                    </td>
                    <td>{{ (item.status) ? $t(`service.${item.status}`) : item.status }}</td>
                    <td>{{ item.agent }}</td>
                    <td v-html="item.created_at"></td>
                    <td></td>
                  </tr>
                </template>
            </Datatable>
          </div>
        </div>
        <!--end::Card-->
      </div>
      <!--end::Container-->
    </div>
    <!--end::Entry-->
  </div>
</template>

<script>
import {mapState} from 'vuex';
import Breadcrumb from '@/components/layout/Breadcrumb.vue'
import Datatable from "@/components/datatable/Datatable.vue";
import api_old from "@/api/index_old";

export default {
  name: 'LevelUpdate',
  data() {
    return {
      isLoading:true,
      datatableUrl: "",
      datatableDataUrl: "",
      datatableData: [],
    }
  },
  components: {
    Breadcrumb,
    Datatable,
  },
  computed: {
    ...mapState({
      attachment_data: state => state.customerManagement.attachment_data,
      permissions: (state) => state.customer.permissions,
      lang: (state) => state.global.lang
    }),
    breadcrumb_data() {
      return {
        title: this.$t('c_management.title'),
        subtitle: [
          this.$t('c_management.user_attachments')
        ]
      }
    }
  },
  methods: {
    checkPermission(permission) {
      return this.permissions?.some(val => val.name == permission);
    },
    loadDatatableData(data) {
      this.$set(this, "datatableData", data);
    },
    initDatatable() {
      this.$refs.datatable.loadData();
    }
  },
  created() {
    if(this.permissions?.length > 0 && !this.checkPermission('crm.users.attachments.index')) {
      this.$router.push('/no-permission')
    }
    this.datatableUrl = api_old.attachment_datatable;
    this.datatableDataUrl = api_old.attachment_datatable_data;
  },
}
</script>
<style lang="scss">
</style>
